<template>
    <div class="publish-result">
        <div class="publish-result-container">
            <div class="publish-result-header"></div>
            <div class="publish-result-content">
                <p class="success-tip">{{resultText}}</p>
                <div class="success-operate">
                    <p v-if="type === 'jobresult'" class="to-email" @click="openDialog">去发邮件</p>
                    <!-- <p v-if="type === 'jobresult'" class="to-job-record" @click="toJobRecord">
                        去职播
                    </p> -->
                </div>
                <!-- <p class="to-job-record-tip">发布职播讲解职位，让顾问推荐更精准。</p> -->
                <div class="result-detail" v-if="type === 'jobresult'">
                    <div class="result-content">
                        <div class="item-wrap">
                            <div class="content-item content-label">
                                总共发布：
                            </div>
                            <div class="content-item content-list">
                                <span>{{result.totalCount}}</span>
                                个职位
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div class="content-item content-label">
                                成功{{result.completedCount}}个：
                            </div>
                            <div class="content-item content-list">
                                <a v-bind:href="getUrl(item.jobId)" class="list-item" v-for="(item, index) in succesList"
                                :key="item.jobId" target="_blank">
                                    <span>{{item.jobName}}</span>
                                    <i v-if="index < (succesList.length -1)">、</i>
                                </a>
                            </div>
                        </div>
                        <div class="item-wrap">
                            <div v-if="result.failedCount > 0" class="content-item content-label">
                                失败{{result.failedCount}}个：
                            </div>
                            <div class="content-item content-list fail-list">
                                <p class="list-item" v-for="(item, index) in failList" :key="item.jobId">
                                    <span>{{item.jobName}}</span>、
                                    <i v-if="index < (failList.length -1)">、</i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="fail-count"></div>
                </div>
            </div>

            <el-dialog title="发邮件" 
                :lock-scroll="false"
                :visible.sync="dialogTableVisible">
                <div class="share-wrap">
                    <div class="share-options-wrap">
                        <p class="share-title">选择邮件展示的字段</p>
                        <div class="share-options">
                            <el-checkbox 
                                v-for="(option, index) in shareOptions" 
                                :key="index" 
                                :label="option.label"
                                v-model="option.isChecked"
                                :disabled="option.key=='name'||option.key=='jobId'?true:false"
                                @change="handleShareOption(option)">
                            </el-checkbox>
                        </div>
                    </div>
                </div>

                <div class="text-left send-file">
                    <span>职位附件</span>
                    <el-checkbox v-model="sendFile">同时发送我刚刚上传的职位附件</el-checkbox>
                </div>

                <div class="submit-btn-group table-center">
                    <div class="btn btn-cancel" @click="dialogTableVisible = false">
                        取消
                    </div>

                    <div class="btn btn-accept" @click="goEmailPage">
                        确定
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: 'publish-result',
	components: {
	},
	data() {
        return {
            result: {},
            batchNo: '',
            type: '',
            resultText: '',
            succesList: [],
            failList: [],
            sendFile: true,
            dialogTableVisible: false,
            shareOptions: [
                {
                    isChecked: true,
                    key: 'name',
                    label: '职位名称'
                },
                {
                    isChecked: true,
                    key: 'targetCompany',
                    label: '所属公司'
                },
                {
                    isChecked: true,
                    key: 'locations',
                    label: '工作地区'
                },
                {
                    isChecked: true,
                    key: 'recruitingCount',
                    label: '招聘人数'
                },
                {
                    isChecked: true,
                    key: 'salary',
                    label: '月薪'
                },
                {
                    isChecked: true,
                    key: 'emergencyDegree',
                    label: '紧急程度'
                },
                {
                    isChecked: true,
                    key: 'description',
                    label: '职位描述'
                },
                {
                    isChecked: true,
                    key: 'analysis',
                    label: '职位分析'
                },
                {
                    isChecked: true,
                    key: 'minYearOfExperience',
                    label: '工作年限'
                },
                {
                    isChecked: true,
                    key: 'minDegree',
                    label: '最低学历'
                },
                {
                    isChecked: true,
                    key: 'jobId',
                    label: '职位链接'
                },

            ],
            pageLoading: false, // 页面加载
        }
    },
    created() {
        this.batchNo = this.$route.query.batchNo;
        this.type = this.$route.query.type;
        this.resultText = this.type === 'emailresult' ? '发送成功!' : '发布成功!';
        this.getRsult();
    },
	mounted() {
		
	},
	methods: {
        handleShareOption(value){
            console.log(value);
        },
        getUrl(id) {
            // return location.origin + '/Headhunting/MyCompany.html#/Job/' + id;
            return location.origin + '/#/Job/' + id;
        },
        goEmailPage() {
            let list = []
            this.shareOptions.map((item) => {
                console.log(item);
                if(item.isChecked){
                    list.push(item.key);
                }
            });

            // console.log('list-------------------');
            // console.log(list);

            if(window.localStorage){
                localStorage.setItem('checkedShareFilds',JSON.stringify(list));
                localStorage.setItem('isSendFile',this.sendFile.toString());
            }
            // let url = `/Headhunting/MyCompany.html#/sendEmail?batchNo=${this.batchNo}`;
            let url = `/#/sendEmail?batchNo=${this.batchNo}`;
            location.href = url;
        },
        openDialog() {
            this.dialogTableVisible = true;
        },
        getRsult() {
            // this.showPageLoading()
            this.pageLoading = true;
            // let url = location.origin + '/Headhunting/JobsBatchImport/GetJobImportResult?batchNo=' + this.batchNo;
            let url = '/Headhunting/JobsBatchImport/GetJobImportResult?batchNo=' + this.batchNo;
            // request(url, {}, 'get', true, true)
            _request({
                url: url,
                method: "GET",
                throwBusinessError: true
            }).then(res => {
                this.result = res;
                this.succesList = res.records.filter((item, index) => {
                    return item.success == true;
                });
                this.failList = res.records.filter((item, index) => {
                    return item.success != true;
                })
            }).catch(err => {
                shortTips(err);
            }).finally(() => {
                this.pageLoading = false;
            });
        },
        toJobRecord() {
            sessionStorage.setItem('multipleCreateJob', true);
            location.href = "/Headhunting/#/myOrders/createdJob";
        },
    }
}
</script>
<style lang="scss">

    .publish-result{
        .el-dialog{
            width: 608px !important;
            .el-dialog__body{
                padding: 30px 80px 0 !important;
            }
        }

        .el-dialog__header,.share-title,.text-left{
            text-align: left !important;
        }

        .el-dialog__header{
            .el-dialog__title{
                font-size: 18px;
            }
        }

        .el-icon-close{
            display: none;
        }

        .el-checkbox{
            color: #38BC9D;

            &:nth-child(4n) {
                margin-right: 0 !important;
            }

            .el-checkbox__inner{
                width: 12px;
                height:12px;
                background-color: #fff;
            }

            .el-checkbox__label{
                color: #666666;
            }

            .el-checkbox__input{
                .el-checkbox__inner {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                    border-color: #DDDDDD !important;
                }

                &.is-checked {
                    .el-checkbox__inner{
                        border-color: #38BC9D !important;
                        background-color: #fff !important;

                        &:after{
                            left: 3px;
                            top: 0px;
                            border: 1px solid #38BC9D;
                            border-left: 0;
                            border-top: 0;
                            height: 6px;
                            width: 3px;
                        }

                        .el-checkbox__label {
                            color: #38BC9D;
                        }
                    }
                }

                &.is-disabled{
                    .el-checkbox__inner {
                        border-color: #d1dbe5 !important;

                        &:after{
                            border-color: #d1dbe5 !important;
                        }
                    }
                }
            }
        }

        .send-file>span{
            margin-right: 22px;
        }

        .share-wrap{
            margin-bottom: 16px;
        }

        .table-center{
            display: table;
            margin: auto;
        }

        .submit-btn-group{
            margin: 20px auto;

            .btn{
                padding: 0;
                width: 100px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                border-radius: 2px;
                font-size: 14px;
            }

            .btn-cancel{
                margin-right: 50px;
                background: #EEEEEE;
                color: #999999;
            }

            .btn-accept{
                color: #fff;
                background-color: #38BC9C;
            }
        }
    }

    .share-options{
        overflow: hidden;
        &:after{
            clear: both;
        }
        .el-checkbox{
            width: 88px !important;
            float: left;
            margin-left: 0 !important;
            text-align: left;
        }
    }

    .publish-result {
        padding: 20px;
        text-align: center;
        height: 100%;
        overflow-y: auto;
        // width: 1000px;
        // margin: 0 auto;
        // min-height: 765px;
        // margin-top: 50px;
        &-container {
            background-color: #fff;
            border-radius: 8px;
            padding-top: 150px;
            padding-bottom: 50px;
            min-height: calc(100vh - 84px);
        }
        &-header {
            // width: 179px;
            // height: 133px;
            width: 110px;
            height: 106px;
            // margin: 50px auto 10px;
            margin: 0 auto;
            // background: url("~@src/assets/images/jobDetail/icon-result.png") no-repeat;
            background: url("~@src/assets/images/jobDetail/publish-result.png") no-repeat;
            background-size: 100%;
        }

        &-content {
            .success-tip {
                text-align: center;
                font-size: 24px;
                color: #444;
                margin: 30px auto 50px;
            }

            .success-operate {
                margin: auto;
                text-align: center;
            }

            .to-email, .to-job-record {
                width: 100px;
                height: 36px;
                line-height: 36px;
                margin-bottom: 10px;
                display: inline-block;
                // border: 1px solid #38bc9c;
                border-radius: 4px;
                cursor: pointer;
                color: #fff;
                background-color: #40BC9D;
            }
            .to-job-record {
                margin-left: 20px;
                // background: #fff;
                position: relative;
            }
            .to-job-record-tip {
                // position: absolute;
                // left: calc(100% + 14px);
                // bottom: 0;
                // font-size: 14px;
                // line-height: 20px;
                // white-space: nowrap;
                // pointer-events: none;
                color: #999999;
                margin-bottom: 30px;
            }

            .result-detail {
                text-align: left;
                width: 530px;
                background: #F5F5F5;
                margin: 0 auto;
                padding: 30px;

                .result-content {
                    .item-wrap {
                        font-size: 0;
                        // margin-bottom: 10px;
                        position: relative;

                        .content-item {
                            font-size: 14px;
                            display: inline-block;

                            &:nth-child(1) {
                                // width: 300px;
                                text-align: right;
                                color: #444;
                                float: left;
                            }

                            &:nth-child(2) {
                                // width: 700px;
                                text-align: left;
                                font-size: 14px;
                                color: #2a2a2a;
                                line-height: 2;

                                span {
                                    color: #ff6807;
                                }

                                a {

                                    span {
                                        color: #666;

                                        &:hover {
                                            color: #38bc9c;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }

                        .content-list {
                            font-size: 0;
                            text-align: left;

                            .list-item {
                                font-size: 14px;
                                display: inline-block;
                                padding: 0 5px;
                                cursor: pointer;
                                color: #2a2a2a;
                            }
                        }
                    }

                    .fail-list {

                        p {
                            margin: 0;
                            padding: 0;
                            display: inline-block;
                            cursor: default !important;

                            span {
                                color: #2a2a2a !important;
                                cursor: text;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
