var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "publish-result" }, [
    _c(
      "div",
      { staticClass: "publish-result-container" },
      [
        _c("div", { staticClass: "publish-result-header" }),
        _c("div", { staticClass: "publish-result-content" }, [
          _c("p", { staticClass: "success-tip" }, [
            _vm._v(_vm._s(_vm.resultText)),
          ]),
          _c("div", { staticClass: "success-operate" }, [
            _vm.type === "jobresult"
              ? _c(
                  "p",
                  { staticClass: "to-email", on: { click: _vm.openDialog } },
                  [_vm._v("去发邮件")]
                )
              : _vm._e(),
          ]),
          _vm.type === "jobresult"
            ? _c("div", { staticClass: "result-detail" }, [
                _c("div", { staticClass: "result-content" }, [
                  _c("div", { staticClass: "item-wrap" }, [
                    _c("div", { staticClass: "content-item content-label" }, [
                      _vm._v(
                        "\n                            总共发布：\n                        "
                      ),
                    ]),
                    _c("div", { staticClass: "content-item content-list" }, [
                      _c("span", [_vm._v(_vm._s(_vm.result.totalCount))]),
                      _vm._v(
                        "\n                            个职位\n                        "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "item-wrap" }, [
                    _c("div", { staticClass: "content-item content-label" }, [
                      _vm._v(
                        "\n                            成功" +
                          _vm._s(_vm.result.completedCount) +
                          "个：\n                        "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "content-item content-list" },
                      _vm._l(_vm.succesList, function (item, index) {
                        return _c(
                          "a",
                          {
                            key: item.jobId,
                            staticClass: "list-item",
                            attrs: {
                              href: _vm.getUrl(item.jobId),
                              target: "_blank",
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(item.jobName))]),
                            index < _vm.succesList.length - 1
                              ? _c("i", [_vm._v("、")])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "item-wrap" }, [
                    _vm.result.failedCount > 0
                      ? _c(
                          "div",
                          { staticClass: "content-item content-label" },
                          [
                            _vm._v(
                              "\n                            失败" +
                                _vm._s(_vm.result.failedCount) +
                                "个：\n                        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "content-item content-list fail-list" },
                      _vm._l(_vm.failList, function (item, index) {
                        return _c(
                          "p",
                          { key: item.jobId, staticClass: "list-item" },
                          [
                            _c("span", [_vm._v(_vm._s(item.jobName))]),
                            _vm._v("、\n                                "),
                            index < _vm.failList.length - 1
                              ? _c("i", [_vm._v("、")])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "fail-count" }),
              ])
            : _vm._e(),
        ]),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "发邮件",
              "lock-scroll": false,
              visible: _vm.dialogTableVisible,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogTableVisible = $event
              },
            },
          },
          [
            _c("div", { staticClass: "share-wrap" }, [
              _c("div", { staticClass: "share-options-wrap" }, [
                _c("p", { staticClass: "share-title" }, [
                  _vm._v("选择邮件展示的字段"),
                ]),
                _c(
                  "div",
                  { staticClass: "share-options" },
                  _vm._l(_vm.shareOptions, function (option, index) {
                    return _c("el-checkbox", {
                      key: index,
                      attrs: {
                        label: option.label,
                        disabled:
                          option.key == "name" || option.key == "jobId"
                            ? true
                            : false,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleShareOption(option)
                        },
                      },
                      model: {
                        value: option.isChecked,
                        callback: function ($$v) {
                          _vm.$set(option, "isChecked", $$v)
                        },
                        expression: "option.isChecked",
                      },
                    })
                  }),
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "text-left send-file" },
              [
                _c("span", [_vm._v("职位附件")]),
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: _vm.sendFile,
                      callback: function ($$v) {
                        _vm.sendFile = $$v
                      },
                      expression: "sendFile",
                    },
                  },
                  [_vm._v("同时发送我刚刚上传的职位附件")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "submit-btn-group table-center" }, [
              _c(
                "div",
                {
                  staticClass: "btn btn-cancel",
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = false
                    },
                  },
                },
                [_vm._v("\n                    取消\n                ")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn btn-accept",
                  on: { click: _vm.goEmailPage },
                },
                [_vm._v("\n                    确定\n                ")]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }